import React from "react"
import Section from "../../Section/Section"
import ContactForm from "../../ContactForm/ContactForm"
import Email from "../../../assets/images/icon-email.svg"
import Phone from "../../../assets/images/icon-phone.svg"
import Address from "../../../assets/images/icon-address.svg"
import Github from "../../../assets/images/icon-github-purple.svg"
import Linkedin from "../../../assets/images/icon-linkedin.svg"
import Medium from "../../../assets/images/icon-medium.svg"
import Youtube from "../../../assets/images/icon-youtube.svg"
import "./ContactSection.scss"

export interface ContactSectionProps {
    contacts: {
        email?: string,
        phoneNumber?: string,
        address?: string,
        githubLink?: string,
        linkedInLink?: string,
        mediumLink?: string,
        youtubeLink?: string,
    }
}

const ContactSection: React.FC<ContactSectionProps> = ({ contacts }) => {
    return (
        <Section sectionName="contact">
            <div className="contact">
                <div className="contact-left">
                    <ContactForm loading={false} error={false} success={false} />
                </div>
                <div className="contact-right">
                    <h2 className="section-title">CONTACT ME</h2>
                    <ContactForm loading={false} error={false} success={false} />
                    <div className="contact-info">
                        <div className="contact-detail">
                            <img src={Email} alt="This is email icon" />
                            <p>{contacts.email}</p>
                        </div>
                        <div className="contact-detail">
                            <img src={Phone} alt="This is phone icon" />
                            <p>{contacts.phoneNumber}</p>
                        </div>
                        <div className="contact-detail">
                            <img src={Address} alt="This is address icon" />
                            <p>{contacts.address}</p>
                        </div>
                    </div>
                    <div className="contact-social-networks">
                        <a href={contacts.githubLink} target="_blank">
                            <img src={Github} alt="This is github icon" />
                        </a>
                        <a href={contacts.linkedInLink} target="_blank">
                            <img src={Linkedin} alt="This is linkedin icon" />
                        </a>
                        <a href={contacts.mediumLink} target="_blank">
                            <img src={Medium} alt="This is facebook icon" />
                        </a>
                        <a
                            href={contacts.youtubeLink}
                            target="_blank"
                        >
                            <img src={Youtube} alt="This is youtube icon" />
                        </a>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default ContactSection