import React from "react"
import "./Layout.scss"
// import Navbar from "../Navbar/Navbar"
import SEO from "../SEO/SEO"

const Layout: React.FC = ({ children }) => {
  return (
    <div className="layout">
      <SEO />
      {/* <Navbar /> */}
      <main className="main">{children}</main>
    </div>
  )
}
export default Layout
