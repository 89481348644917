import React from "react"
import Section from "../../Section/Section"
import Grid from "../../Grid/Grid"
import { FluidObject } from "gatsby-image"
import "./TechnologySection.scss"

interface TechIcon {
    fluid: FluidObject
    iconUrl: string
    alt: string
}

interface TechnologySectionProps {
    technologyList: TechIcon[]
}

const TechnologySection: React.FC<TechnologySectionProps> = ({ technologyList }) => {
    return (
        <Section sectionName="technologies">
            <Grid gridType="forTechnologies" technologies={technologyList} />
        </Section>
    )
}

export default TechnologySection