import React from "react"
import Section from "../../Section/Section"
import Button from "../../Button/Button"
import "./AboutMeSection.scss"

export interface AboutMeSectionProps {
    description: string,
    cvUrl: string,
    imageSrc: string
}

const AboutMeSection: React.FC<AboutMeSectionProps> = ({ description, cvUrl, imageSrc }) => {
    return (
        <Section sectionName="aboutMe">
            <div className="aboutMe">
                <div className="aboutMe-left">
                    <h2 className="aboutMe-left__title">ABOUT ME</h2>
                    <p className="aboutMe-left__description">
                        {description
                            .split("\n\n")
                            .map((para, i, arr) =>
                                i == arr.length - 1 ? (
                                    para
                                ) : (
                                        <span key={i}>
                                            {para}
                                            <br />
                                            <br />
                                        </span>
                                    )
                            )}
                    </p>
                    <Button
                        type="button"
                        isDownload
                        name="My Resume"
                        cvUrl={cvUrl}
                    />
                </div>
                <img
                    src={imageSrc}
                    alt=""
                    className="aboutMe-right-image"
                />
            </div>
        </Section>
    )
}

export default AboutMeSection