import React from "react"
import Section from "../../Section/Section"
import "./HeaderSection.scss"

const HeaderSection = () => {
    return (
        <Section sectionName="header" />
    )
}

export default HeaderSection