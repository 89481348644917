import React, { useContext } from "react"
import "./Card.scss"
import ProjectContext from "../../contexts/ProjectContext"
import { smoothScroll } from "../scrolling"
import { useMediaQuery } from "react-responsive"
export interface CardProps {
  title: string
  shortDesc: string
  index: number
}

const Card: React.FC<CardProps> = ({ title, shortDesc, index }) => {
  const { setProjectIndex } = useContext(ProjectContext) as {
    projectIndex: number
    setProjectIndex: React.Dispatch<React.SetStateAction<number>>
  }

  const block = useMediaQuery({
    query: "(max-width: 1680px)",
  }) ? "start" : "center"

  const scrollTo = (id: string) => {
    const projectImageElement = document.getElementById(id);

    if (projectImageElement) {
      projectImageElement.scrollIntoView({ behavior: "smooth", block: block })
    } else {
      smoothScroll(id)
    }
  }

  return (
    <div
      className={`card card-background-${index + 1}`}
      onClick={() => {
        setProjectIndex(index)
        document.getElementById("project--title")!.offsetTop <
          document.getElementById("project--img")!.offsetTop
          ? scrollTo("project--title")
          : scrollTo("project--img")
      }}
    >
      <h3 className="card--title">{title}</h3>
      <p className="card--shortDesc">{shortDesc}</p>
    </div>
  )
}

export default Card
