import React from "react"
import "./Grid.scss"
import Card, { CardProps } from "../Card/Card"

type GridName = "forCards" | "forTechnologies"

interface Technology {
    iconUrl: string
    alt: string
}

interface SectionProps {
    gridType: GridName
    cards?: CardProps[]
    technologies?: Technology[]
}

const Grid = ({ gridType = "forCards", cards, technologies }: SectionProps) => {
    return (
        <div className={`grid-container-${gridType}`}>
            {cards &&
                cards.map((card, i) => (
                    <div className={`item-${i + 1}`} key={i}>
                        <Card
                            title={card.title}
                            shortDesc={card.shortDesc}
                            index={card.index}
                        />
                    </div>
                ))}
            {technologies &&
                technologies.map((skill, i) => (
                    <img src={skill.iconUrl} alt={skill.alt} key={i} />
                ))}
        </div>
    )
}

export default Grid
