import React from "react"
import Section from "../../Section/Section"
import Grid from "../../Grid/Grid"
import { FluidObject } from "gatsby-image"
import CardSlider from "../../CardSlider/CardSlider"
import "./ProjectSection.scss"

interface Project {
    projectName: string
    shortDescription: { shortDescription: string }
    fullDescription: {
        fullDescription: string
    }
    index: number
    projectImage: {
        fluid: FluidObject
    }
    title: string
    shortDesc: string
    fullDesc: string
    websiteLink: string
    githubLink: string
    youtubeLink: string
}

interface ProjectSectionProps {
    projects: Project[]
}

const ProjectSection: React.FC<ProjectSectionProps> = ({ projects }) => {
    return (
        <Section sectionName="projects">
            <div className="projects">
                <h3 className="projects-s-title">WHAT I DO ?</h3>
                <Grid gridType="forCards" cards={projects} />
                <div className="projects-slider">
                    <CardSlider cardArray={projects} />
                </div>
            </div>
        </Section>
    )
}

export default ProjectSection