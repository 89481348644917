import React, { useState } from "react"
import Layout from "../components/Layout/Layout"
import HeaderSection from "../components/Sections/HeaderSection/HeaderSection"
import AboutMeSection from "../components/Sections/AboutMeSection/AboutMeSection"
import ProjectSection from "../components/Sections/ProjectSection/ProjectSection"
import ProjectInfoSection from "../components/Sections/ProjectInfoSection/ProjectInfoSection"
import TechnologySection from "../components/Sections/TechnologySection/TechnologySection"
import ContactSection from "../components/Sections/ContactSection/ContactSection"
import FooterSection from "../components/Sections/FooterSection/FooterSection"
import "./HomePage.scss"
import { graphql } from "gatsby"
import { FluidObject } from "gatsby-image"
import ProjectContext from "../contexts/ProjectContext"

interface AboutMe {
    description: {
        description: string
    }
    image: { fluid: FluidObject }
    resume: { file: { url: string } }
}
interface Project {
    projectName: string
    shortDescription: { shortDescription: string }
    fullDescription: {
        fullDescription: string
    }
    index: number
    projectImage: {
        fluid: FluidObject
    }
    title: string
    shortDesc: string
    fullDesc: string
    websiteLink: string
    githubLink: string
    youtubeLink: string
}
interface TechIcon {
    fluid: FluidObject
    iconUrl: string
    alt: string
}
interface Props {
    data: {
        homepage: {
            aboutMe: AboutMe
            projects: Project[]
            technologySet: TechIcon[]
            contactMe: {
                email: string
                githubLink: string
                address: string
                phoneNumber: string
                youtubeLink: string
                linkedinLink: string
                mediumLink: string
            }
            copyRight: string
        }
    }
}

const IndexPage: React.FC<Props> = ({ data }) => {
    const {
        aboutMe,
        projects,
        technologySet,
        contactMe,
        copyRight,
    } = data.homepage


    projects.map((project, i) => {
        project.index = i
        project.title = project.projectName
        project.shortDesc = project.shortDescription.shortDescription
        project.fullDesc = project.fullDescription.fullDescription
        return project
    })

    technologySet.length = 8
    technologySet.map(icon => {
        icon.iconUrl = icon.fluid.src
        icon.alt = "Technology Icon"
    })

    const [projectIndex, setProjectIndex] = useState(0)

    return (
        <ProjectContext.Provider value={{ projectIndex, setProjectIndex }}>
            <Layout>
                <HeaderSection />
                <AboutMeSection description={aboutMe.description.description} cvUrl={aboutMe.resume.file.url} imageSrc={aboutMe.image.fluid.src} />
                <ProjectSection projects={projects} />
                <ProjectInfoSection projects={projects} projectIndex={projectIndex} />
                <TechnologySection technologyList={technologySet} />
                <ContactSection contacts={contactMe} />
                <FooterSection licence={copyRight} />
            </Layout>
        </ProjectContext.Provider>
    )
}

export default IndexPage

export const query = graphql`
  query MyQuery {
    homepage: contentfulHomePage {
      aboutMe {
        description {
          description
        }
        image {
          fluid {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        resume {
          file {
            url
          }
        }
      }
      projects {
        fullDescription {
          fullDescription
        }
        youtubeLink
        shortDescription {
          shortDescription
        }
        projectName
        githubLink
        websiteLink
        projectImage {
          fluid {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      technologySet {
        fluid {
          base64
          tracedSVG
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      contactMe {
        email
        githubLink
        address
        phoneNumber
        youtubeLink
        linkedinLink
        mediumLink
      }
      copyRight
    }
  }
`
