import React from "react"
import "./Section.scss"
import classNames from "classnames"

type SectionName =
    | "header"
    | "aboutMe"
    | "projects"
    | "projectInfo"
    | "technologies"
    | "contact"
    | "footer"

interface SectionProps {
    sectionName: SectionName
    children?: React.ReactNode
}

const Section = ({ sectionName, children }: SectionProps) => {
    const sectionClasses = classNames({
        ["section"]: true,
        [`section--${sectionName}`]: sectionName,
    })
    return (
        <section id={sectionName} className={sectionClasses}>
            {children}
        </section>
    )
}

export default Section
