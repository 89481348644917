import React from "react"
import Section from "../../Section/Section"
import Button from "../../Button/Button"
import "./FooterSection.scss"

export interface FooterSectionProps {
    licence: string,
}

const FooterSection: React.FC<FooterSectionProps> = ({ licence }) => {
    return (
        <Section sectionName="footer">
            <br />
            <p className="licence">{licence}</p>
            <p className="licence">
                Free photo by Bruce Mars on
<a href="https://www.pexels.com/"> Pexels</a>
            </p>
            <p className="licence">
                Web icons by
<a href="https://icons8.com/icons"> icons8</a>
            </p>
            <br />
        </Section>
    )
}

export default FooterSection