import React from "react"
import Section from "../../Section/Section"
import Github from "../../../assets/images/icon-github-purple.svg"
import Youtube from "../../../assets/images/icon-youtube.svg"
import Website from "../../../assets/images/icon-website.svg"
import { FluidObject } from "gatsby-image"
import "./ProjectInfoSection.scss"

interface Project {
    projectName: string
    shortDescription: { shortDescription: string }
    fullDescription: {
        fullDescription: string
    }
    index: number
    projectImage: {
        fluid: FluidObject
    }
    title: string
    shortDesc: string
    fullDesc: string
    websiteLink: string
    githubLink: string
    youtubeLink: string
}

interface ProjectInfoSectionProps {
    projects: Project[]
    projectIndex: number
}

const ProjectInfoSection: React.FC<ProjectInfoSectionProps> = ({ projects, projectIndex }) => {
    return (
        <Section sectionName="projectInfo">
            <div className="projectInfo">
                <div className="projectInfo-left">
                    <img
                        src={projects[projectIndex].projectImage.fluid.src}
                        alt="this is project image"
                        className="projectInfo-left-img"
                        id="project--img"
                    />
                </div>
                <div className="projectInfo-right">
                    <div>
                        <h2 className="projectInfo-right--title" id="project--title">
                            {projects[projectIndex].title}
                        </h2>
                        <div className="projectInfo-right--links">
                            {projects[projectIndex].websiteLink && (
                                <a
                                    href={projects[projectIndex].websiteLink}
                                    target="_blank"
                                >
                                    <img src={Website} alt="This is github icon" />
                                </a>
                            )}
                            {projects[projectIndex].githubLink && (
                                <a href={projects[projectIndex].githubLink} target="_blank">
                                    <img src={Github} alt="This is github icon" />
                                </a>
                            )}
                            {projects[projectIndex].youtubeLink && (
                                <a
                                    href={projects[projectIndex].youtubeLink}
                                    target="_blank"
                                >
                                    <img src={Youtube} alt="This is github icon" />
                                </a>
                            )}
                        </div>
                        <p className="projectInfo-right--desc">
                            {projects[projectIndex].fullDesc
                                .split("\n\n")
                                .map((para, i, arr) =>
                                    i == arr.length - 1 ? (
                                        para
                                    ) : (
                                            <span key={i}>
                                                {para}
                                                <br />
                                                <br />
                                            </span>
                                        )
                                )}
                        </p>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default ProjectInfoSection