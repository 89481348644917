import React, { FC } from "react"
import "./Button.scss"
import classNames from "classnames"
import Download from "../../assets/images/download.png"
export type btnType = "button" | "submit" | "reset" | undefined

interface ButtonProps {
  // this is the name of button
  name: string

  type: btnType

  isDownload?: boolean
  // this needs an event triggered once clicked
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void

  cvUrl?: string
}
const Button: React.FC<ButtonProps> = ({
  name,
  type,
  isDownload = false,
  cvUrl,
  onClick,
}) => {
  const btnClasses = classNames({
    ["btn"]: true,
    ["btn__download"]: isDownload,
  })
  return !isDownload ? (
    <button className={btnClasses} type={type} onClick={onClick}>
      {name}
    </button>
  ) : (
    <a href={cvUrl} target="_blank">
      <button className={btnClasses} type={type} onClick={onClick}>
        <span>{name}</span>
        <img src={Download} alt="This is download button" />
      </button>
    </a>
  )
}

export default Button
