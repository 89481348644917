import React, { useContext } from "react"
import Swiper from "react-id-swiper"
import "./CardSlider.scss"
import "swiper/css/swiper.css"
import Card, { CardProps } from "../Card/Card"
import { useMediaQuery } from "react-responsive"
import ProjectContext from "../../contexts/ProjectContext"

interface CardSliderProps {
  cardArray: CardProps[]
}

const CardSlider: React.FC<CardSliderProps> = ({ cardArray }) => {
  function isMobile() {
    return useMediaQuery({
      query: "(max-width: 625px)",
    })
  }

  const paramsOnMobile = {
    effect: "cube",
    grabCursor: true,
    cubeEffect: {
      shadow: true,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
    pagination: {
      el: ".swiper-pagination",
    },
    on: {
      // on mobile portrait
      touchStart(event) {
        startX = event.touches[0].clientX
        endX = event.touches[0].clientX
      },
      touchEnd(event) {
        index === -1 ? (index = 0) : index
        var deltaX = startX - endX

        deltaX > 155 && index !== 6
          ? setProjectIndex(++index)
          : deltaX < -155 && index !== 0
          ? setProjectIndex(--index)
          : null
      },
      touchMove(event) {
        endX = event.touches[0].clientX
      },

      // on mobile landscape
      slideNextTransitionStart: function() {
        index !== 6 ? setProjectIndex(++index) : index
      },
      slidePrevTransitionStart: function() {
        index !== 0 ? setProjectIndex(--index) : index
      },
    },
  }

  var startX = 0
  var endX = 0
  const paramsOnTablet = {
    allowTouchMove: false,
    effect: "flip",
    ContainerEl: "section",
    WrapperEl: "section",
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
    },
    loop: true,
    on: {
      slideNextTransitionStart: function() {
        index !== 6
          ? setProjectIndex(++index)
          : (setProjectIndex(0), (index = 0))
      },
      slidePrevTransitionStart: function() {
        index !== 0
          ? setProjectIndex(--index)
          : (setProjectIndex(6), (index = 6))
      },
    },
  }

  const { projectIndex, setProjectIndex } = useContext(ProjectContext) as {
    projectIndex: number
    setProjectIndex: React.Dispatch<React.SetStateAction<number>>
  }

  // avoid the first time auto triggered due to using Swiper "flip" on tablet
  var index = -1

  return isMobile() ? (
    <Swiper {...paramsOnMobile}>
      {cardArray.map(card => (
        <div key={card.index}>
          <Card
            title={card.title}
            index={card.index}
            shortDesc={card.shortDesc}
          />
        </div>
      ))}
    </Swiper>
  ) : (
    <Swiper {...paramsOnTablet}>
      {cardArray.map(card => (
        <div key={card.index}>
          <Card
            title={card.title}
            index={card.index}
            shortDesc={card.shortDesc}
          />
        </div>
      ))}
    </Swiper>
  )
}

export default CardSlider
