import React from "react"
import "./ContactForm.scss"
import NetlifyForm from "react-netlify-form"
import Button from "../Button/Button"

interface ContactFormProps {
  loading: boolean
  error: boolean
  success: boolean
}
const ContactForm: React.FC<ContactFormProps> = ({
  loading,
  error,
  success,
}) => {
  return (
    <div className="form-container">
      <NetlifyForm name="Contact Form" data-netlify="true">
        {() => (
          <div>
            <div className="input-item">
              <label htmlFor="name">Full name</label>
              <input
                data-testid="input"
                id="name"
                type="text"
                name="name"
                required
              />
            </div>
            <div className="input-item">
              <label htmlFor="email">Email</label>
              <input
                data-testid="input"
                id="email"
                type="email"
                name="email"
                required
              />
            </div>
            <div className="input-item">
              <label htmlFor="company">Company</label>
              <input
                data-testid="input"
                id="company"
                type="text"
                name="company"
              />
            </div>
            <div className="input-item">
              <label htmlFor="message">Messages</label>
              <textarea
                data-testid="input"
                id="message"
                name="message"
                required
              />
            </div>
            {success && <p>success</p>}
            <Button
              type="submit"
              name="Send"
              onClick={() =>
                alert("Thank you for your message, I will contact to you soon.")
              }
            />
          </div>
        )}
      </NetlifyForm>
    </div>
  )
}

export default ContactForm
